import React, { useCallback, useEffect, useState } from 'react';
import BVComponentsOld from '@bv/components-old';
import StepTitle from '../StepTitle';
import { ContainerStyled } from './styles';
import { Controller, useForm } from 'react-hook-form';
import InputField from '../../components/InputField';
import { REGEX_DATE, REGEX_EMAIL, REGEX_FULLNAME, REGEX_TELEFONE } from '../../constants';
import { PatternFormat } from 'react-number-format';
import ReactSelect, { TOptions } from '../ReactSelect';
import { Invite } from '../../../../shared/interfaces/invite.interface';
import { Form } from '../../interfaces/form.interface';
import { Link } from 'react-router-dom';
import ValidatorStrings from '../../../../shared/classes/ValidatorStrings';
import { JOBS } from '../../../../shared/data/profissoes';
import AsyncSelect from 'react-select/async';
import Highlighter from 'react-highlight-words';

const NO_OPTION = 'NENHUM-RESULTADO';

type FormValues = {
  name: string;
  crm: string;
  cpf: string;
  email: string;
  rnm: string;
  birthdate: Date;
  gender: string;
  jobPosition: string;
  workplace: string;
  cellphone: string;
};

const options: TOptions[] = [
  {
    label: 'Feminino',
    value: 'F',
  },
  {
    label: 'Masculino',
    value: 'M',
  },
];

type PersonalStepProps = {
  visible: boolean;
  setStep: (step: 1 | 2 | 3) => void;
  invite: Invite;
  setForm: React.Dispatch<React.SetStateAction<Form>>;
};

const PersonalStep = ({ visible, setStep, invite, setForm }: PersonalStepProps) => {
  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm<FormValues>({ mode: 'onTouched' });

  const [isCpf, setIsCpf] = useState(true);

  useEffect(() => {
    setValue('name', invite?.name);
    setValue('crm', invite?.crm);
    setValue('email', invite?.email);
    setValue('workplace', invite?.company?.name);
  }, [invite?.company?.name, invite?.crm, invite?.email, invite?.name, setValue]);

  const onSubmit = (data) => {
    let jobPosition = data.jobPosition.value;
    if (data?.jobPosition?.value === NO_OPTION) {
      jobPosition = data?.jobPosition?.label;
    }
    setForm((old) => ({
      ...old,
      name: data.name,
      crm: data.crm,
      cpf: isCpf ? data.cpf : data.rnm,
      birthdate: data.birthdate,
      gender: data.gender,
      cellphone: data.cellphone,
      email: data.email,
      jobPosition: jobPosition,
    }));
    setStep(2);
  };

  const searchJobs = useCallback((text: string) => {
    const _text = text.trim();

    if (!_text) return;
    let arr = [...JOBS];
    arr = arr.filter((item) => item?.toLowerCase().includes(_text?.trim()?.toLowerCase()));

    const filtered = arr.slice(0, 100);
    const options: TOptions[] = filtered.map((item) => ({
      label: item.toString(),
      value: item.toString(),
    }));

    options.push({
      label: _text,
      value: NO_OPTION,
    });

    return options;
  }, []);

  const promiseOptions = (inputValue: string) =>
    new Promise<any[]>((resolve) => {
      setTimeout(() => {
        resolve(searchJobs(inputValue));
      }, 300);
    });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ContainerStyled className="row" visible={visible}>
        <div className="col-xs-12">
          <StepTitle>Dados Pessoais</StepTitle>
        </div>
        <div className="col-xs-12 mt-3">
          <InputField label="Nome completo" showError={!!errors?.name?.type}>
            <input type="text" placeholder="Digite seu nome completo" {...register('name', { required: true, pattern: REGEX_FULLNAME })} />
          </InputField>
        </div>
        {invite.crm && (
          <div className="col-xs-12">
            <InputField label="CRM" showError={!!errors?.crm?.type}>
              <input type="text" className="form-control" disabled {...register('crm', { required: true })} />
            </InputField>
          </div>
        )}
        <div className="col-md-6 col-xs-12">
          <div
            className="flex items-center justify-between"
            style={{
              marginBottom: '0.5rem',
            }}>
            <label htmlFor={'cpf'} className="form--label m-0">
              {isCpf ? 'CPF' : 'RNM (Antigo RNE)'}
            </label>

            <label htmlFor={'rne-for'} className="form--label m-0" onClick={() => setIsCpf((old) => !old)}>
              <input type="radio" checked={false} onChange={() => null} />
              {!isCpf ? 'Utilizar CPF' : 'RNM (Antigo RNE)'}
            </label>
          </div>

          {isCpf ? (
            <InputField label="" showError={!!errors?.cpf?.type} id="cpf" className="cpf__field">
              <Controller
                rules={{
                  required: true,
                  validate: (cpf) => ValidatorStrings.validateCpf(cpf),
                }}
                name={`cpf`}
                render={({ field: { onChange, onBlur, value, name, ref }, fieldState: { invalid, isTouched, isDirty, error }, formState }) => (
                  <PatternFormat
                    format="###.###.###.##"
                    mask="_"
                    onBlur={onBlur}
                    // onFocus={}
                    name={name}
                    onChange={onChange}
                    placeholder="000.000.000-00"
                    type="text"
                  />
                )}
                control={control}
              />
            </InputField>
          ) : (
            <InputField label="" showError={!!errors?.rnm?.type} id="rnm" className="rnm__field">
              <Controller
                rules={{
                  required: true,
                  validate: (text: string) => {
                    const _text = text.trim().replace(/\-/g, '').replace(/\_/g, '');

                    return _text.length >= 8;
                  },
                }}
                name={`rnm`}
                render={({ field: { onChange, onBlur, value, name, ref }, fieldState: { invalid, isTouched, isDirty, error }, formState }) => (
                  <BVComponentsOld.FormMask
                    className="rnm__field__input"
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    required
                    mask="#######-#"
                    placeholder="X000000-X"
                    onChange={onChange}
                    type="text"
                  />
                )}
                control={control}
              />
            </InputField>
          )}
        </div>
        <div className="col-md-6 col-xs-12">
          <InputField showError={!!errors?.birthdate?.type} label="Data de nascimento" id="birthdate">
            <input
              type="date"
              name=""
              id="birthdate"
              {...register('birthdate', {
                required: true,
                pattern: REGEX_DATE,
                validate: (text) => ValidatorStrings.validateDateBeforeToday(text),
              })}
            />
          </InputField>
        </div>
        <div className="col-md-6 col-xs-12">
          <InputField showError={!!errors?.gender?.type} label="Sexo biológico" id="gender">
            <Controller
              control={control}
              rules={{
                required: true,
              }}
              name="gender"
              render={({ field: { onChange, onBlur, value, name, ref } }) => (
                <ReactSelect
                  value={options.find((c) => c.value === value)}
                  options={options}
                  placeholder="Selecionar..."
                  isSearchable={false}
                  onChange={(val) => onChange(val?.value)}
                  showError={!!errors?.gender?.type}
                  id="gender"
                  name={name}
                  className="w-100"
                />
              )}
            />
          </InputField>
        </div>
        <div className="col-md-6 col-xs-12">
          <InputField label="Celular" showError={!!errors?.cellphone?.type} id="cellphone">
            <Controller
              rules={{
                required: true,
                pattern: {
                  value: REGEX_TELEFONE,
                  message: 'Número Inválido',
                },
              }}
              name={`cellphone`}
              render={({ field: { onChange, onBlur, value, name, ref }, fieldState: { invalid, isTouched, isDirty, error }, formState }) => (
                <PatternFormat
                  format="(##) ##########"
                  mask=""
                  onBlur={onBlur}
                  // value={value}
                  name={name}
                  onChange={onChange}
                  placeholder="(00) 00000-0000"
                  type={'tel'}
                  id="cellphone"
                />
              )}
              control={control}
            />
          </InputField>
        </div>
        <div className="col-xs-12">
          <InputField label="E-mail" showError={!!errors?.email?.type} id="email">
            <input
              type="email"
              id="email"
              placeholder="Digite seu email"
              className="form-control"
              disabled
              {...register('email', {
                required: true,
                pattern: REGEX_EMAIL,
              })}
            />
          </InputField>
        </div>

        <div className="col-xs-12">
          <InputField label="Local de trabalho" showError={!!errors?.workplace?.type}>
            <input
              type="text"
              placeholder=""
              className="form-control"
              disabled
              {...register('workplace', {
                required: false,
              })}
            />
          </InputField>
        </div>
        <div className="col-xs-12 mb-2">
          <div className="flex justify-between">
            <span className="font-size-14 text-primary">Plano da unidade:</span>
            <span className="font-size-14 font-semibold">{invite?.planData?.name}</span>
          </div>
        </div>

        <div className="col-xs-12 position-relative">
          <label className="text-blue-dark font-size-14 mb-2 font-semibold">
            Qual seu cargo? {errors?.jobPosition?.type && <span className="text-danger font-size-10">* Obrigatório</span>}
          </label>
          <Controller
            rules={{
              required: true,
            }}
            name={`jobPosition`}
            render={({ field: { onChange, name, ref } }) => (
              <AsyncSelect
                ref={ref}
                name={name}
                cacheOptions
                defaultOptions
                noOptionsMessage={() => 'Digite o nome do seu cargo'}
                placeholder="Insira o nome do seu cargo"
                loadOptions={promiseOptions}
                onChange={onChange}
                isClearable
                formatOptionLabel={(data, ctx) => {
                  if (data.value === NO_OPTION && ctx.context == 'menu') {
                    return <button className="btn btn-link">Adicionar {`"${data?.label}”`}</button>;
                  }
                  // return <>{data?.label}</>
                  return (
                    <>
                      <Highlighter
                        highlightClassName="text-neutral-darkest bg-transparent p-0 font-semibold"
                        searchWords={[ctx?.inputValue]}
                        autoEscape={false}
                        textToHighlight={data?.label}
                      />
                    </>
                  );
                }}
              />
            )}
            control={control}
          />
        </div>

        <div className="col-xs-12 mt-3">
          <button type="submit" className="btn btn-primary w-100 flex items-center justify-center">
            Continuar
          </button>
        </div>
        <div className="col-xs-12 mt-2">
          <Link to="/login" className="btn btn-outline-primary w-100">
            <strong>Já tem cadastro? Faça seu login</strong>
          </Link>
        </div>
      </ContainerStyled>
    </form>
  );
};

export default PersonalStep;
