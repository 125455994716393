import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import BVUtilsOld from '@bv/utils-old';
import BVComponentsOld from '@bv/components-old';
import AddressStep from '../../components/AddressStep';
import ContentHeader from '../../components/ContentHeader';
import LoadingInvite from '../../components/LoadingInvite';
import PasswordStep from '../../components/PasswordStep';
import PersonalStep from '../../components/PersonalStep';
import Steps from '../../components/Steps';
import { ArrowLeft } from 'lucide-react';
import { ContainerStyled, ContentStyled, TitleStyled } from './styles';
import { Form } from '../../interfaces/form.interface';
import { Invite } from '../../../../shared/interfaces/invite.interface';

import * as singleSpa from 'single-spa';

const Invited: React.FC = () => {
  const [loadingInvite, setLoadingInvite] = useState(true);
  const [step, setStep] = useState(1);
  const [invalidInvite, setInvalidInvite] = useState(false);
  const [invite, setInvite] = useState<Invite>(undefined);
  const [messageError, setMessageError] = useState(undefined);
  const [isSSO, setIsSSO] = useState(false);
  const [form, setForm] = useState<Form>({
    inviteId: '',
    name: '',
    email: '',
    crm: '',
    cpf: '',
    cellphone: '',
    roles: [],
    password: '',
    repeatPassword: '',
    acceptedTerms: false,
    gender: '',
    birthdate: null,
    address: {
      zipCode: '',
      street: '',
      number: '',
      complement: '',
      neighbourhood: '',
      city: '',
      state: '',
    },
  });

  useEffect(() => {
    const inviteId = new URLSearchParams(window.location.search).get('_i');
    !inviteId ? setInvalidInvite(true) : loadInvite(inviteId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function loadInvite(id: string) {
    setLoadingInvite(true);
    try {
      const { data } = await BVUtilsOld.api.inviteApi.getById(id);
      setForm({
        ...form,
        inviteId: id,
        roles: data.company?.roles,
        root: data.root || false,
        crm: data.crm || undefined,
      });
      // if (data.company?.sso?.clientId && data?.isSSOEnabled) {
      if (data.company?.hasSSOEnabled && data?.isSSOEnabled) {
        setIsSSO(true);
      }
      setInvite(data);
    } catch (err) {
      console.log('err: ', err);
      setInvalidInvite(true);
      setMessageError(err?.message);
    } finally {
      setLoadingInvite(false);
    }
  }

  if (invalidInvite) {
    return (
      <div className="absolute bottom-0 left-0 flex h-screen w-screen flex-col items-center justify-center gap-3 bg-white">
        <h6>{messageError || 'Convite não encontrado'}</h6>
        <BVComponentsOld.Button className="btn btn-outline-primary" variant="outline-primary" onClick={() => singleSpa.navigateToUrl('/login')}>
          <strong>Já tem cadastro? Faça seu login</strong>
        </BVComponentsOld.Button>
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Bem-vindo(a) ao BV </title>
      </Helmet>
      <div className="h-100 overflow-auto">
        {!loadingInvite ? (
          <ContainerStyled>
            <TitleStyled>Benefício Visão</TitleStyled>
            <ContentStyled>
              {step == 2 && (
                <button className="i-btn-goback" onClick={() => setStep(1)}>
                  <ArrowLeft width={24} height={24} />
                  <span>Voltar aos dados pessoais</span>
                </button>
              )}
              {step == 3 && !isSSO && (
                <button className="i-btn-goback" onClick={() => setStep(2)}>
                  <ArrowLeft width={24} height={24} />
                  <span>Voltar ao endereço</span>
                </button>
              )}

              <ContentHeader invite={invite} step={step} />
              <Steps step={step} setStep={setStep} isSSO={isSSO} />

              <>
                {/* steps */}
                <PersonalStep visible={step === 1} setStep={setStep} invite={invite} setForm={setForm} />
                <AddressStep visible={step === 2} setStep={setStep} setForm={setForm} form={form} isSSO={isSSO} />
                <PasswordStep visible={step === 3} form={form} />
              </>
            </ContentStyled>
          </ContainerStyled>
        ) : (
          <LoadingInvite />
        )}
      </div>
    </>
  );
};

export default Invited;
